<template>
  <div class="questions-creator">
    <question-list v-if="!adding" :tab="tabCtl" />
    <question-create-edit v-if="adding" />

    <div v-if="questionsLen && !adding">
      <br />
      <v-card elevation="0">
        <v-card-text>
          Minimum number of questions for approval: ({{ min_qty }}/{{
            questionsLen
          }})
          <br />
          <br />
          <br />
          <v-slider
            v-model="min_qty"
            :step="1"
            :max="questionsLen"
            thumb-label
            ticks
            tick-size="8"
          />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// parei na parte de criar os sistemas de edição e remoção das questões e opções
// lembrar de criar um "watch" pra quando a pessoa ajustar o limite minimo de questões acertadas
import StringHelper from '../../helpers/strings';
import validatorRules from '../../helpers/validator_rules';
import QuestionList from './QuestionList.vue';
import QuestionCreateEdit from './QuestionCreateEdit.vue';

export default {
  props: {
    tab: {
      type: Number,
      default: 0,
    },
  },
  components: {
    QuestionList,
    QuestionCreateEdit,
  },
  data: _ => ({
    tabCtl: 0,
    isValid: false,
    err: {
      answer_text: false,
      answer_response: false,
      message: false,
    },
    new_question: {
      id: '',
      type: '0',
      title: {},
      result: null,
      answers: [],
    },
  }),
  computed: {
    requiredRule() {
      return validatorRules.required('Required');
      // return validatorRules.required( this.$t('rules.required') )
    },
    questionsLen() {
      return this.$store.state.questions.list.length;
    },
    adding() {
      return this.$store.state.questions.isAdding;
    },
    min_qty: {
      get() {
        return this.$store.state.questions.min;
      },
      set(value) {
        this.$store.commit('questions/setMin', value);
      },
    },
  },
  watch: {
    tab: function(value) {
      this.tabCtl = value;
    },
    'new_question.result': function(value) {
      if (value != null) {
        this.err.message = false;
      }
    },
    min_questions: function() {
      // this.$emit( 'input', {
      //     min_questions: this.min_questions,
      //     questions: this.questions
      // })
    },
    // value: function( value ){
    //     this.min_questions = value.min_questions
    //     this.questions = value.questions
    // }
  },
  created() {
    // this.min_questions = this.value.min_questions
    // this.questions = this.value.questions
  },
  mounted() {
    // this.min_questions = this.value.min_questions
    // this.questions = this.value.questions
  },
  methods: {
    add_question() {
      this.$refs.q_form.validate();
      if (!this.isValid || this.new_question.answers.length < 2) return false;
      if (
        this.new_question.answers.length >= 2 &&
        this.new_question.type == '0' &&
        this.new_question.result == null
      ) {
        setTimeout(
          function() {
            this.err.answer_response = false;
          }.bind(this),
          1000
        );
        this.err.answer_response = true;
        this.err.message = true;
        return false;
        // tem que ajeitar essa parada aqui
      }

      if (this.new_question.id != '') {
        var currentQuestionIndex = this.questions.findIndex(
          item => item.id == this.new_question.id
        );
        this.questions[currentQuestionIndex] = JSON.parse(
          JSON.stringify(this.new_question)
        );
      } else {
        this.new_question.id = StringHelper.random(24);
        this.questions.push(JSON.parse(JSON.stringify(this.new_question)));
      }

      // this.$emit( 'input', {
      //     min_questions: this.min_questions,
      //     questions: this.questions
      // })
      this.new_question = {
        id: '',
        type: '0',
        title: '',
        result: null,
        answers: [],
      };

      this.adding = false;
      this.changeMode();
    },
    cancel_question() {
      this.new_question = {
        id: '',
        type: '0',
        title: '',
        result: null,
        answers: [],
      };

      this.adding = false;
      this.changeMode();
    },
    // create_question() {
    //     this.adding = true
    //     this.changeMode()
    // },
    sortQuestions() {
      // this.$nextTick(()=>{
      //     this.$emit( 'input', {
      //         min_questions: this.min_questions,
      //         questions: this.questions
      //     })
      // })
    },
    question_edit(item) {
      this.adding = true;
      this.changeMode();
      this.new_question = {
        id: item.id,
        type: item.type,
        title: item.title,
        result: item.result,
        answers: item.answers,
      };
    },
    async question_remove(index) {
      var response = await this.$store.dispatch('dialog/show', {
        title: 'Remove',
        content: 'Do you want remove this question?',
      });
      if (response) {
        this.questions.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss">
.answer_row {
  display: flex;
  position: relative;
  width: 100%;
  transition: background-color 0.8s;

  .answer_actions {
    position: absolute;
    right: 0;
    top: 0;
  }

  &.err {
    background-color: #ff0000;
  }
}
.question_row {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  border-bottom: 1px solid #2a3c4d;
  position: relative;

  .actions {
    position: absolute;
    right: 20px;
  }
}
.questions-creator {
  padding-bottom: 30px;
  .check_responses {
    margin-top: 16px;
    padding-bottom: 18px;
    .v-input--selection-controls {
      margin: 0;

      .v-input__control {
        padding-bottom: 4px;
      }
    }
  }
  .add-q {
    text-align: center;
    background-color: #ffc107;
    padding: 10px 0;
  }
  .answer-input {
    border: 1px solid #d2d2d2;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #fff;
    outline: none;
    padding: 2px 10px 1px 10px;
    box-sizing: border-box;
    width: 100%;
    transition: background-color 0.8s;

    &.highlight_error {
      background-color: #ff0000;
    }
  }
  .answer-input-btn {
    background-color: #ffc107;
    padding: 2px 10px 1px 10px;
    display: inline-block;
  }
  .type-select {
    margin: 0;

    .v-messages {
      display: none;
    }
  }
  .type-select {
    .s-title {
      padding-right: 10px;
    }
  }
  .err,
  .message_err {
    color: #ff0000;
  }
}
</style>

<!-- <div
    v-for="(element, index) in questions"
    :key="index">
    <div class="q-title">
        {{element.title}}
    </div>

    {{element}}

    <v-radio-group
        v-if="element.type === '0'"
        v-model="element.result">
        <v-radio
            v-for="(elem, idx) in element.answers"
            :key="'ar_' + idx"
            :label="elem.title"
            :value="elem.check">
        </v-radio>
    </v-radio-group>

    <div v-else class="check_responses">
        <v-checkbox
            v-for="(elem, idx) in element.answers"
            :key="'ac_' + idx"
            :label="elem.title"
            v-model="elem.check"
            hide-details>
        </v-checkbox>
    </div>
</div> -->
