import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(!_vm.adding)?_c('f-lang-selector',{attrs:{"langs":_vm.course.langs}}):_vm._e(),(!_vm.adding)?_c(VTabsItems,{model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}},_vm._l((_vm.course.langs),function(lang){return _c(VTabItem,{key:lang},[_c('div',{staticClass:"input-spacer"},[_c(VTextField,{attrs:{"rules":_vm.requiredRule,"outlined":"","label":_vm.$tt.capitalize(_vm.$t('labels.vimeoID')) +
                  _vm.$tt.uppercase(` (${lang})`)},model:{value:(_vm.lesson.content.video[lang]),callback:function ($$v) {_vm.$set(_vm.lesson.content.video, lang, $$v)},expression:"lesson.content.video[lang]"}})],1)])}),1):_vm._e()],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(!_vm.adding)?_c('f-lang-selector',{attrs:{"langs":_vm.course.langs}}):_vm._e(),(!_vm.adding)?_c(VTabsItems,{model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}},_vm._l((_vm.course.langs),function(lang){return _c(VTabItem,{key:lang},[_c('div',{staticClass:"input-spacer"},[_c(VTextarea,{attrs:{"outlined":"","aut-grow":"","no-resize":"","rows":"5","label":_vm.$tt.capitalize(_vm.$t('labels.moduleDescription')) +
                  _vm.$tt.uppercase(` (${lang})`)},model:{value:(_vm.lesson.description[lang]),callback:function ($$v) {_vm.$set(_vm.lesson.description, lang, $$v)},expression:"lesson.description[lang]"}})],1)])}),1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }