<template>
  <div>
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <f-lang-selector :langs="course.langs" />
      <v-tabs-items v-model="selectedLang">
        <v-tab-item v-for="lang in course.langs" :key="lang">
          <div class="input-spacer">
            <v-text-field
              v-model="new_answer.title[lang]"
              outlined
              :class="{ highlight_error: err.answer_text }"
              :rules="requiredRule"
              :label="
                $tt.capitalize($t('labels.answer')) +
                  $tt.uppercase(` (${lang})`)
              "
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
      <v-btn color="primary" elevation="0" @click="add_answer">{{
        $t('labels.saveAnswer')
      }}</v-btn>
      &nbsp;
      <v-btn elevation="0" @click="cancel">{{ $t('labels.cancel') }}</v-btn>
    </v-form>
  </div>
</template>

<script>
import validatorRules from '../../helpers/validator_rules';
import stringHelper from '../../helpers/strings';

export default {
  data: () => ({
    new_answer: {
      id: '',
      title: {},
      check: false,
    },
    err: {
      answer_text: false,
      answer_response: false,
      message: false,
    },
    isValid: false,
  }),
  computed: {
    course() {
      return this.$store.getters['course/current'];
    },
    selectedLang() {
      return this.$store.getters['system/selectedInputsLang'];
    },
    question() {
      return this.$store.state.questions.question;
    },
    langs() {
      return this.$store.state.questions.langs;
    },
    tab() {
      return this.$store.state.questions.langCtl;
    },
    requiredRule() {
      return validatorRules.required('Required');
    },
    tab: {
      get() {
        return this.$store.state.questions.langCtl;
      },
      set(value) {
        this.$store.commit('questions/switchLang', value);
      },
    },
  },
  created() {
    if (this.$store.state.questions.answer != '') {
      this.get();
    }
  },
  methods: {
    get() {
      const index = this.$store.state.questions.question.answers
        .map(item => item.id)
        .indexOf(this.$store.state.questions.answer);
      this.new_answer = this.$store.state.questions.question.answers[index];
    },
    add_answer() {
      this.$refs.form.validate();
      if (!this.isValid) return false;

      if (!validatorRules.checkLangData(this.new_answer.title, this.langs)) {
        this.$store.commit('header/showAlert', {
          content: this.$t('alerts.general.verifyLanguages'),
          type: 'error',
        });
        this.$store.commit('snackbar/show', {
          content: this.$t('alerts.general.verifyLanguages'),
          type: 'error',
        });
        return false;
      }

      this.$store.commit('questions/clearAlerts');
      this.$store.commit('header/clearAlert');

      if (this.new_answer.id != '') {
        this.cancel();
      } else {
        this.new_answer.id = stringHelper.random(24);
        this.$store.commit('questions/answerAdd', this.new_answer);
      }
    },
    cancel() {
      this.$store.commit('questions/setAnswerAdding', false);
    },
  },
};
</script>
