<template>
  <div class="input-edit-file">
    <div
      class="ief--old-filename-div"
      v-show="showOldFileDiv"
      :class="{ removing: toRemove }"
    >
      <div v-if="!toRemove">
        {{ fileURL }}
      </div>
      <div v-else>{{ $t("labels.fileWillBeRemove") }}</div>
      <div class="ief--ctl">
        <div class="ief--btn edit" @click="select" v-if="!readOnly">
          {{ $t("labels.new") }}<v-icon>mdi-upload</v-icon>
        </div>
      </div>
    </div>
    <v-file-input
      v-show="!showOldFileDiv"
      ref="ief_fileimput"
      outlined
      show-size
      @change="change"
      :label="fileLabel"
    />
  </div>
</template>

<script>
export default {
  props: ["file", "params", "disabled", "url", "label"],
  data: () => ({
    hasFile: false,
    toRemove: false,
    bckFile: "",
    showOldFileDiv: false,
    readOnly: false,
  }),
  created() {
    this.readOnly = this.disabled === true;
    if (!this.file) {
      this.hasFile = false;
    } else {
      {
        this.hasFile = true;
      }
    }
    this.showOldFileDiv = this.hasFile;
  },
  watch: {
    file() {
      if (!this.file) {
        this.hasFile = false;
      } else {
        {
          this.hasFile = true;
        }
      }
      this.showOldFileDiv = this.hasFile;
    },
  },
  methods: {
    async select() {
      this.$refs.ief_fileimput.$refs.input.click();
    },
    change(newFile) {
      if (this.hasFile && !newFile) {
        this.showOldFileDiv = true;
        newFile = this.file;
      } else {
        this.showOldFileDiv = false;
      }

      this.$emit("change", {
        value: newFile,
        params: this.params,
        ref: this,
      });
    },
  },
  computed: {
    fileURL() {
      let fileName = this.file;
      if (this.file && typeof this.file === "object") {
        fileName = this.file.name;
      }
      return fileName;
    },
    fileLabel() {
      if (this.label) {
        return this.label;
      }
      return this.$t("labels.file");
    },
  },
};
</script>

<style lang="scss">
.input-edit-file {
  .ief--old-filename-div {
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 5px 18px;
    margin-bottom: 30px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s;

    &.removing {
      background-color: #d85252;
    }
  }

  .ief--ctl {
    display: flex;
    flex-shrink: 0;

    .ief--btn {
      background-color: #e0dede;
      margin-left: 10px;
      padding: 3px 10px 3px 14px;
      border-radius: 4px;
      transition: background-color 0.3s;
      cursor: pointer;
      color: inherit;
      text-decoration: none;

      &:hover {
        background-color: #c5c5c5;
      }

      i {
        margin-left: 5px;
      }
    }
  }
}
</style>
