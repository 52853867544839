<!-- Create Edit Form - PDF -->
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <f-lang-selector :langs="course.langs" v-if="!adding" />
        <v-tabs-items v-model="selectedLang" v-if="!adding">
          <v-tab-item v-for="lang in course.langs" :key="lang">
            <div class="input-spacer">
              <v-file-input
                v-if="!$store.getters['system/isEditPage']"
                accept="application/pdf"
                @change="setFile"
                :rules="requiredRule"
                outlined
                :label="
                  $tt.capitalize($t('labels.pdf')) + $tt.uppercase(` (${lang})`)
                "
              />
              <f-input-edit-file
                v-if="$store.getters['system/isEditPage']"
                :url="$API_URL + 'course/pdf/'"
                :file="lesson.content.pdf[courseCurrentLanguage]"
                @change="updateFile"
                @remove="() => {}"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="js">
import FInputEditFile from '../../shared/InputEditFile.vue';

export default {
	components: {
		FInputEditFile
	},
	data: () => ({
		$API_URL: '',
		adding: false,
		flags: {
			isEditing: false
		},
		requiredRule: []
	}),
	computed: {
		course() {
			return this.$store.getters['course/current']
		},
		courseCurrentLanguage() {
			return this.$store.getters['course/courseCurrentLanguage']
		},
		selectedLang(){
			return this.$store.getters['system/selectedInputsLang']
		},
		lesson() {
			return this.$store.state.lesson.current
		},
	},
	created(){
		if (this.$store.getters['system/isEditPage']) {
			this.$store.state.lesson.current.content = {}
			this.lesson.content['pdf'] = {...this.lesson.data}
		} else {
			this.lesson.content['pdf'] = {}
		}
	},
	methods: {
		setFile(file){
			this.lesson.content.pdf[this.course.langs[this.selectedLang]] = file
		},
		updateFile(file){
			this.lesson.content.pdf[this.course.langs[this.selectedLang]] = file.value
		}
	}
}
</script>
