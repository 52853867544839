<template>
  <f-container topBottom>
    <v-card elevation="0">
      <v-card-text>
        <v-form v-model="flags.isValid" ref="form" @submit.prevent>
          <f-lang-selector :langs="course.langs" v-if="!adding" />
          <v-tabs-items v-model="selectedLang" v-if="!adding">
            <v-tab-item v-for="lang in course.langs" :key="lang">
              <div class="input-spacer">
                <v-text-field
                  v-model="lesson.title[lang]"
                  :rules="[requireAllLang('title')]"
                  outlined
                  :label="
                    $tt.capitalize($t('labels.title')) +
                      $tt.uppercase(` (${lang})`)
                  "
                />
              </div>
            </v-tab-item>
          </v-tabs-items>

          <div v-if="!flags.isEditing && !adding">
            <v-radio-group
              inline
              v-model="type"
              row
              @changed="
                () => {
                  // console.log('indeisde change');
                }
              "
            >
              <template v-slot:label>
                <div>{{ $tt.capitalize($t('labels.type')) }}:</div>
              </template>
              <v-radio
                v-for="(lesson, index) in lessonTypes"
                :label="$tt.capitalize($t(`labels.${lesson.title}`))"
                :key="index"
                :value="`${lesson.value}`"
              />
            </v-radio-group>
          </div>

          <f-video v-if="type == '0'" />
          <f-pdf v-if="type == '1'" />
          <f-exam v-if="type == '2'" />
          <f-material v-if="type == '3'" />
          <f-post v-if="type == '4'" />
          <!-- <f-scorm v-if="type == '5'" /> -->
        </v-form>
      </v-card-text>
    </v-card>

    <!-- <f-support-material /> -->

    <lms-btn-holder>
      <v-btn color="primary" large elevation="0" @click="save">
        {{ flags.isEditing ? $t('labels.save') : $t('labels.create') }}
      </v-btn>

      <v-btn large depressed @click="back">
        {{ $t('labels.cancel') }}
      </v-btn>
    </lms-btn-holder>
  </f-container>
</template>

<script>
import lessonTypes from '../../../data/lessonTypes';
import validatorRules from '../../../helpers/validator_rules';

import FVideo from './CEFVideo.vue';
import FPdf from './CEFPdf.vue';
import FExam from './CEFExam.vue';
import FMaterial from './CEFMaterial.vue';
import FPost from './CEFText.vue';
import FScorm from './CEFScorm.vue';
import FSupportMaterial from './CEFSuportMaterials.vue';

export default {
  components: {
    FVideo,
    FPdf,
    FExam,
    FMaterial,
    FPost,
    FScorm,
    FSupportMaterial,
  },
  data: () => ({
    type: '0',
    lessonTypes,
    flags: {
      isEditing: false,
      isValid: false,
    },
    tab: 0,
    adding: false,
  }),
  computed: {
    course() {
      return this.$store.getters['course/current'];
    },
    lesson() {
      return this.$store.state.lesson.current;
    },
    selectedLang() {
      return this.$store.getters['system/selectedInputsLang'];
    },
    requiredRule() {
      return validatorRules.required(this.$t('rules.required'));
    },
  },
  watch: {
    type: function(value) {
      this.lesson.type = value;
    },
  },
  created() {
    if (this.$store.getters['system/isEditPage']) {
      this.type = this.lesson.category.toString();
    }
  },
  methods: {
    async save() {
      const lessonId = this.$route.params.lessonid;
      const action = lessonId ? 'edit' : 'new';

      const valid = this.$refs.form.validate();
      if (!valid) return;

      await this.$store.dispatch('lesson/save');
      this.back();
      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: `${action}-course-lesson`,
        value: lessonId ? `edit-${lessonId}` : 'new',
      });
    },
    back() {
      this.$router.push({
        name: 'course-view',
        params: {
          id: this.$route.params.courseid,
        },
      });
    },
    requireAllLang(value) {
      let response = false;
      if (validatorRules.checkLangData(this.lesson[value], this.course.langs)) {
        response = true;
      } else {
        response = this.$t('rules.allLangsField');
      }
      return response;
    },
  },
};
</script>
