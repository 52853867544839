<!-- Create Edit Form - Exam -->
<template>
  <div>
    <div>
      <f-question-module :tab="tab" />
    </div>
    <div v-if="!adding">
      <v-checkbox
        :label="$t('labels.generateCertificate')"
        v-model="lesson.generate_certificate"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import FQuestionModule from '../../QuestionModule/Index.vue';

export default {
  components: {
    FQuestionModule,
  },
  data: () => ({
    adding: false,
    tab: 0,
  }),
  computed: {
    lesson() {
      return this.$store.state.lesson.current;
    },
  },
  created() {
    if (this.$store.getters['system/isEditPage']) {
      this.$store.state.questions.list = this.$store.state.lesson.current.data.questions;
      this.$store.state.questions.min = this.$store.state.lesson.current.data.min_questions;
    }
  },
  destroyed() {
    this.$store.commit('questions/cleanData');
  },
};
</script>
