<!-- Create Edit Form - Video -->
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <f-lang-selector :langs="course.langs" v-if="!adding" />
        <v-tabs-items v-model="selectedLang" v-if="!adding">
          <v-tab-item v-for="lang in course.langs" :key="lang">
            <div class="input-spacer">
              <v-text-field
                v-model="lesson.content.video[lang]"
                :rules="requiredRule"
                outlined
                :label="
                  $tt.capitalize($t('labels.vimeoID')) +
                    $tt.uppercase(` (${lang})`)
                "
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <f-lang-selector :langs="course.langs" v-if="!adding" />
        <v-tabs-items v-model="selectedLang" v-if="!adding">
          <v-tab-item v-for="lang in course.langs" :key="lang">
            <div class="input-spacer">
              <v-textarea
                v-model="lesson.description[lang]"
                outlined
                aut-grow
                no-resize
                rows="5"
                :label="
                  $tt.capitalize($t('labels.moduleDescription')) +
                    $tt.uppercase(` (${lang})`)
                "
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="js">
import lessonTypes from '../../../data/lessonTypes';
import validatorRules from '../../../helpers/validator_rules';

export default {
	data: () => ({
		lessonTypes,
		isEditing: false,
			flags: {
      isValid: false,
    },
		tab: 0,
		adding: false,
		lang: 'en'
	}),
	computed: {
		course() {
			return this.$store.getters['course/current']
		},
    selectedLang(){
			return this.$store.getters['system/selectedInputsLang']
		},
		lesson() {
			return this.$store.state.lesson.current
		},
		requiredRule() {
      return validatorRules.required(this.$t('rules.required'));
    },
	},
	created() {
    if(this.$route.name.includes('edit')){
      this.lesson.content = this.lesson.data
    } else {
      this.lesson.content = { video: {} }
    }
		this.lesson.description = {}
		// this.lesson.type = '0'
		// content
	}
}
</script>
