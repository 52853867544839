import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f-container',{attrs:{"topBottom":""}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.flags.isValid),callback:function ($$v) {_vm.$set(_vm.flags, "isValid", $$v)},expression:"flags.isValid"}},[(!_vm.adding)?_c('f-lang-selector',{attrs:{"langs":_vm.course.langs}}):_vm._e(),(!_vm.adding)?_c(VTabsItems,{model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}},_vm._l((_vm.course.langs),function(lang){return _c(VTabItem,{key:lang},[_c('div',{staticClass:"input-spacer"},[_c(VTextField,{attrs:{"rules":[_vm.requireAllLang('title')],"outlined":"","label":_vm.$tt.capitalize(_vm.$t('labels.title')) +
                    _vm.$tt.uppercase(` (${lang})`)},model:{value:(_vm.lesson.title[lang]),callback:function ($$v) {_vm.$set(_vm.lesson.title, lang, $$v)},expression:"lesson.title[lang]"}})],1)])}),1):_vm._e(),(!_vm.flags.isEditing && !_vm.adding)?_c('div',[_c(VRadioGroup,{attrs:{"inline":"","row":""},on:{"changed":() => {
                // console.log('indeisde change');
              }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.$tt.capitalize(_vm.$t('labels.type')))+":")])]},proxy:true}],null,false,3639555558),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},_vm._l((_vm.lessonTypes),function(lesson,index){return _c(VRadio,{key:index,attrs:{"label":_vm.$tt.capitalize(_vm.$t(`labels.${lesson.title}`)),"value":`${lesson.value}`}})}),1)],1):_vm._e(),(_vm.type == '0')?_c('f-video'):_vm._e(),(_vm.type == '1')?_c('f-pdf'):_vm._e(),(_vm.type == '2')?_c('f-exam'):_vm._e(),(_vm.type == '3')?_c('f-material'):_vm._e(),(_vm.type == '4')?_c('f-post'):_vm._e()],1)],1)],1),_c('lms-btn-holder',[_c(VBtn,{attrs:{"color":"primary","large":"","elevation":"0"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.flags.isEditing ? _vm.$t('labels.save') : _vm.$t('labels.create'))+" ")]),_c(VBtn,{attrs:{"large":"","depressed":""},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t('labels.cancel'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }