import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.lesson.type == 0 || _vm.lesson.type == 4)?_c('div',[_vm._v(" Support Materials "),_c('f-lang-selector',{attrs:{"langs":_vm.course.langs}}),(!_vm.adding)?_c(VTabsItems,{model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}},_vm._l((_vm.course.langs),function(lang){return _c(VTabItem,{key:lang},[_c('div',{staticClass:"input-spacer"},[_vm._l((_vm.newContent.material),function(item,index){return _c('div',{key:index,staticClass:"material-item"},[(item[lang])?_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"rules":_vm.requiredRule,"hide-details":"","outlined":"","label":_vm.$tt.capitalize(_vm.$t('labels.materialName')) +
                    _vm.$tt.uppercase(` ${index + 1} (${lang})`)},model:{value:(item[lang].name),callback:function ($$v) {_vm.$set(item[lang], "name", $$v)},expression:"item[lang].name"}})],1),_c(VCol,[(!_vm.$store.getters['system/isEditPage'])?_c(VFileInput,{attrs:{"rules":_vm.requiredRule,"outlined":"","hide-details":"","label":_vm.$tt.capitalize(_vm.$t('labels.file')) +
                    _vm.$tt.uppercase(` ${index + 1} (${lang})`)},on:{"change":file => _vm.setMaterial(index, file)}}):_vm._e(),(_vm.$store.getters['system/isEditPage'])?_c('div',{staticStyle:{"margin-bottom":"-30px"}},[_c('input-edit-file',{attrs:{"url":_vm.$API_URL + 'course/material/',"file":_vm.newContent.material[index][lang].file,"hide-details":""},on:{"change":file => _vm.updateMaterial(index, file),"remove":() => {}}})],1):_vm._e()],1),_c(VCol,{staticClass:"flex-grow-0",attrs:{"align-self":"center"}},[_c(VBtn,{attrs:{"icon":"","disabled":_vm.newContent.material.length === 1},on:{"click":function($event){$event.preventDefault();return (() => _vm.removeMaterial(index)).apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)],1):_vm._e()],1)}),_c('div',{staticClass:"material-actions"},[(!_vm.adding)?_c(VBtn,{attrs:{"color":"primary","small":"","elevation":"0","disabled":_vm.sending,"loading":_vm.sending},on:{"click":function($event){$event.preventDefault();return _vm.addMaterial.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('labels.addMaterial'))+" ")],1):_vm._e()],1)],2)])}),1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }