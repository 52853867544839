import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-question-div"},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(!_vm.isAnswerAdding)?_c('div',[_c('f-lang-selector',{attrs:{"langs":_vm.course.langs},model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}}),_c(VTabsItems,{model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}},_vm._l((_vm.course.langs),function(lang){return _c(VTabItem,{key:lang},[_c('div',{staticClass:"input-spacer"},[_c(VTextField,{attrs:{"outlined":"","rules":_vm.requiredRule,"label":_vm.$tt.capitalize(_vm.$t('labels.question')) +
                  _vm.$tt.uppercase(` (${lang})`)},model:{value:(_vm.question.title[lang]),callback:function ($$v) {_vm.$set(_vm.question.title, lang, $$v)},expression:"question.title[lang]"}})],1),_c('div',{staticClass:"input-spacer"},[(!_vm.question.id)?_c(VFileInput,{attrs:{"outlined":"","label":_vm.$tt.capitalize(_vm.$t('labels.image')) +
                  _vm.$tt.uppercase(` (${lang})`)},on:{"change":file => _vm.setFile(file, lang)}}):_vm._e(),(_vm.question.id)?_c('input-edit-file',{attrs:{"url":_vm.$API_URL + 'course/question/',"file":_vm.question.image ? _vm.question.image[lang] : null,"label":_vm.$tt.capitalize(_vm.$t('labels.image')) +
                  _vm.$tt.uppercase(` (${lang})`)},on:{"change":file => _vm.updateFile(file, lang),"remove":() => {}}}):_vm._e()],1)])}),1),_c('div',[_c(VRadioGroup,{staticClass:"type-select",attrs:{"row":""},model:{value:(_vm.question.type),callback:function ($$v) {_vm.$set(_vm.question, "type", $$v)},expression:"question.type"}},[_c('span',{staticClass:"s-title"},[_vm._v(_vm._s(_vm.$t('labels.type')))]),_c(VRadio,{attrs:{"label":"radio","value":"0"}}),_c(VRadio,{attrs:{"label":"checkbox","value":"1"}})],1)],1)],1):_vm._e(),(!_vm.isAnswerAdding)?_c('answer-list'):_vm._e(),(_vm.isAnswerAdding)?_c('answer-create-edit'):_vm._e(),(!_vm.isAnswerAdding)?_c('div',[_c('br'),_c(VBtn,{attrs:{"color":"primary black--text","elevation":"0"},on:{"click":_vm.add_question}},[_vm._v(_vm._s(_vm.question.id !== '' ? _vm.$t('labels.saveQuestion') : _vm.$t('labels.addQuestion')))]),_vm._v("   "),_c(VBtn,{attrs:{"elevation":"0"},on:{"click":_vm.cancel_question}},[_vm._v(_vm._s(_vm.$t('labels.cancel')))])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }