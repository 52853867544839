<template>
  <div class="answers-wrapper">
    {{ $t('labels.answerList') }}
    <div class="err" v-if="!question.answers.length">
      {{ $t('labels.answerListEmpty') }}
    </div>
    <div v-if="isAlerting">{{ $t('labels.youNeddTwoQuestion') }}</div>
    <div v-if="question.type == '0'">
      <v-radio-group v-model="question.result">
        <div class="message_err" v-if="err.message">
          {{ $t('labels.youNeddChooseTheRightAnswer') }}
        </div>
        <SlickList
          lockAxis="y"
          v-model="question.answers"
          :useDragHandle="true"
          @sort-end="clearAnswer"
        >
          <SlickItem
            v-for="(el, index) in question.answers"
            :index="index"
            :key="index"
          >
            <div class="answer_row" :class="{ err: err.answer_response }">
              <span v-handle class="handle">
                <v-icon>mdi-dots-grid</v-icon>
              </span>
              <v-radio
                hide-details
                :label="el.title[course.langs[selectedLang]]"
                :value="el.id"
              />
              <div class="answer_actions">
                <a @click.stop="answerEdit(el.id)">
                  <v-icon>mdi-pencil</v-icon>
                </a>
                <a @click.stop="answerRemove(index)">
                  <v-icon>mdi-delete</v-icon>
                </a>
              </div>
            </div>
          </SlickItem>
        </SlickList>
      </v-radio-group>
    </div>

    <div v-else class="check_responses">
      <SlickList
        lockAxis="y"
        v-model="question.answers"
        :useDragHandle="true"
        @sort-end="clearAnswer"
      >
        <SlickItem
          v-for="(el, index) in question.answers"
          :index="index"
          :key="index"
        >
          <div class="answer_row" :class="{ err: err.answer_response }">
            <span v-handle class="handle"><v-icon>mdi-dots-grid</v-icon></span>
            <v-checkbox
              :label="el.title[course.langs[selectedLang]]"
              v-model="el.check"
              hide-details
            >
            </v-checkbox>
            <div class="answer_actions">
              <a @click.stop="answerEdit(el.id)"><v-icon>mdi-pencil</v-icon></a>
              <a @click.stop="answerRemove(el.id)"
                ><v-icon>mdi-delete</v-icon></a
              >
            </div>
          </div>
        </SlickItem>
      </SlickList>
    </div>
    <div class="add-q" @click="create">
      <v-icon>mdi-plus</v-icon> {{ $t('labels.addAnswer') }}
    </div>
  </div>
</template>

<script>
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';

export default {
  components: {
    SlickItem,
    SlickList,
  },
  directives: {
    handle: HandleDirective,
  },
  data: () => ({
    err: {
      answer_text: false,
      answer_response: false,
      message: false,
    },
  }),
  computed: {
    course() {
      return this.$store.getters['course/current'];
    },
    selectedLang() {
      return this.$store.getters['system/selectedInputsLang'];
    },
    question() {
      return this.$store.state.questions.question;
    },
    langs() {
      return this.$store.state.questions.langs;
    },
    tab() {
      return this.$store.state.questions.langCtl;
    },
    isAlerting() {
      return this.$store.state.questions.alerts.answers;
    },
  },
  methods: {
    create() {
      this.$store.commit('questions/setAnswerAdding', true);
    },
    answerEdit(id) {
      this.$store.commit('questions/answerEdit', id);
    },
    async answerRemove(id) {
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.courses.removeAnswer.title'),
        content: this.$t('alerts.courses.removeAnswer.content'),
      });
      if (response) {
        this.$store.commit('questions/answerRemove', id);
      }
    },
    clearAnswer() {
      this.question.result = null;
    },
  },
};
</script>
