<template>
  <div class="create-question-div">
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <div v-if="!isAnswerAdding">
        <f-lang-selector v-model="selectedLang" :langs="course.langs" />

        <v-tabs-items v-model="selectedLang">
          <v-tab-item v-for="lang in course.langs" :key="lang">
            <div class="input-spacer">
              <v-text-field
                outlined
                v-model="question.title[lang]"
                :rules="requiredRule"
                :label="
                  $tt.capitalize($t('labels.question')) +
                    $tt.uppercase(` (${lang})`)
                "
              />
            </div>
            <div class="input-spacer">
              <v-file-input
                v-if="!question.id"
                @change="file => setFile(file, lang)"
                outlined
                :label="
                  $tt.capitalize($t('labels.image')) +
                    $tt.uppercase(` (${lang})`)
                "
              />
              <input-edit-file
                v-if="question.id"
                :url="$API_URL + 'course/question/'"
                :file="question.image ? question.image[lang] : null"
                @change="file => updateFile(file, lang)"
                @remove="() => {}"
                :label="
                  $tt.capitalize($t('labels.image')) +
                    $tt.uppercase(` (${lang})`)
                "
              />
            </div>
          </v-tab-item>
        </v-tabs-items>

        <div>
          <v-radio-group v-model="question.type" row class="type-select">
            <span class="s-title">{{ $t('labels.type') }}</span>
            <v-radio label="radio" value="0"></v-radio>
            <v-radio label="checkbox" value="1"></v-radio>
          </v-radio-group>
        </div>
      </div>

      <answer-list v-if="!isAnswerAdding" />
      <answer-create-edit v-if="isAnswerAdding" />

      <div v-if="!isAnswerAdding">
        <br />
        <v-btn
          color="primary black--text"
          elevation="0"
          @click="add_question"
          >{{
            question.id !== ''
              ? $t('labels.saveQuestion')
              : $t('labels.addQuestion')
          }}</v-btn
        >
        &nbsp;
        <v-btn elevation="0" @click="cancel_question">{{
          $t('labels.cancel')
        }}</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import validatorRules from '../../helpers/validator_rules';

import AnswerList from './AnswerList';
import AnswerCreateEdit from './AnswerCreateEdit';
import InputEditFile from '../shared/InputEditFile';

export default {
  components: {
    AnswerList,
    AnswerCreateEdit,
    InputEditFile,
  },
  data: () => ({
    isValid: false,
    new_answer: {
      id: '',
      title: {},
      check: false,
    },
    err: {
      answer_text: false,
      answer_response: false,
      message: false,
    },
  }),
  computed: {
    course() {
      return this.$store.getters['course/current'];
    },
    selectedLang() {
      return this.$store.getters['system/selectedInputsLang'];
    },
    adding() {
      return this.$store.state.questions.isAdding;
    },
    isAnswerAdding() {
      return this.$store.state.questions.isAnswerAdding;
    },
    langs() {
      return this.$store.state.questions.langs;
    },
    requiredRule() {
      return validatorRules.required(this.$t('rules.required'));
    },
    tab: {
      get() {
        return this.$store.state.questions.langCtl;
      },
      set(value) {
        this.$store.commit('questions/switchLang', value);
      },
    },
    question: {
      get() {
        return this.$store.state.questions.question;
      },
      set(value) {
        this.$store.commit('questions/setQuestion', value);
      },
    },
  },
  created() {},
  methods: {
    add_question() {
      this.$refs.form.validate();
      if (!this.isValid) return false;

      if (!validatorRules.checkLangData(this.question.title, this.langs)) {
        this.$store.commit('header/showAlert', {
          content: this.$t('alerts.general.verifyLanguages'),
          type: 'error',
        });
        this.$store.commit('snackbar/show', {
          content: this.$t('alerts.general.verifyLanguages'),
          type: 'error',
        });
        return false;
      }

      if (this.question.answers.length < 2) {
        this.$store.commit('header/showAlert', {
          content: this.$t('alerts.general.verifyAnswers'),
          type: 'error',
        });
        this.$store.commit('questions/alertAnswers');
        return false;
      }

      if (this.question.type == '0' && this.question.result == null) {
        this.$store.commit('snackbar/show', {
          content: this.$t('alerts.general.answerSelectError'),
          type: 'error',
        });
        return false;
      }

      this.$store.commit('questions/clearAlerts');
      this.$store.commit('header/clearAlert');

      if (this.question.id != '') {
        this.$store.commit('questions/questionEdit');
      } else {
        this.$store.commit('questions/questionAdd');
      }
    },
    cancel_question() {
      this.$store.commit('questions/questionCancel');
    },
    setFile(file, lang) {
      if (!this.question.image) {
        this.question.image = {};
      }
      this.question.image[lang] = file;
      this.$forceUpdate();
    },
    updateFile(file, lang) {
      if (!this.question.image) {
        this.question.image = {};
      }
      this.question.image[lang] = file.value;
      this.$forceUpdate();
    },
  },
};
</script>
