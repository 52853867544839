import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(!_vm.adding)?_c('f-lang-selector',{attrs:{"langs":_vm.course.langs}}):_vm._e(),(!_vm.adding)?_c(VTabsItems,{model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}},_vm._l((_vm.course.langs),function(lang){return _c(VTabItem,{key:lang},[_c('div',{staticClass:"input-spacer"},[(!_vm.$store.getters['system/isEditPage'])?_c(VFileInput,{attrs:{"accept":"application/pdf","rules":_vm.requiredRule,"outlined":"","label":_vm.$tt.capitalize(_vm.$t('labels.pdf')) + _vm.$tt.uppercase(` (${lang})`)},on:{"change":_vm.setFile}}):_vm._e(),(_vm.$store.getters['system/isEditPage'])?_c('f-input-edit-file',{attrs:{"url":_vm.$API_URL + 'course/pdf/',"file":_vm.lesson.content.pdf[_vm.courseCurrentLanguage]},on:{"change":_vm.updateFile,"remove":() => {}}}):_vm._e()],1)])}),1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }