<template>
  <div class="list-question">
    {{ $t('labels.questionList') }}
    <div class="err" v-if="!questions.length && !adding">
      {{ $t('labels.questionListEmpty') }}
    </div>
    <div v-if="isAlerting">{{ $t('labels.youNeedOneQuestion') }}</div>
    <SlickList
      lockAxis="y"
      v-model="questions"
      @sort-end="sort"
      :useDragHandle="true"
    >
      <SlickItem v-for="(item, index) in questions" :index="index" :key="index">
        <div class="question question_row">
          <span v-handle class="handle"><v-icon>mdi-dots-grid</v-icon></span>

          {{ item.title[course.langs[selectedLang]] }}

          <div class="actions">
            <a @click.stop="edit(item.id)"><v-icon>mdi-pencil</v-icon></a>
            <a @click.stop="remove(item.id)"><v-icon>mdi-delete</v-icon></a>
          </div>
        </div>
      </SlickItem>
    </SlickList>
    <div class="add-q" @click="create">
      <v-icon>mdi-plus</v-icon> {{ $t('labels.addQuestion') }}
    </div>
  </div>
</template>

<script>
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';

export default {
  props: {
    tab: {
      type: Number,
      default: 0,
    },
  },
  components: {
    SlickItem,
    SlickList,
  },
  directives: {
    handle: HandleDirective,
  },
  computed: {
    course() {
      return this.$store.getters['course/current'];
    },
    selectedLang() {
      return this.$store.getters['system/selectedInputsLang'];
    },
    langs() {
      return this.$store.state.questions.langs;
    },
    questions: {
      get() {
        return this.$store.state.questions.list;
      },
      set(value) {
        this.$store.commit('questions/setQuestions', value);
      },
    },
    adding() {
      return this.$store.state.questions.isAdding;
    },
    isAlerting() {
      return this.$store.state.questions.alerts.questions;
    },
  },
  created() {},
  mounted() {},
  methods: {
    create() {
      this.$store.commit('questions/setAdding', true);
    },
    edit(id) {
      this.$store.commit(
        'questions/setQuestion',
        this.$store.state.questions.list.find(item => item.id == id)
      );
      this.$store.commit('questions/setAdding', true);
    },
    async remove(id) {
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.courses.removeQuestion.title'),
        content: this.$t('alerts.courses.removeQuestion.content'),
      });
      if (response) {
        this.$store.commit('questions/questionRemove', id);
      }
    },
    sort() {},
  },
};
</script>
