<!-- Create Edit Form - Text -->
<template>
  <div>
    <f-lang-selector :langs="course.langs" />
    <v-tabs-items v-model="selectedLang">
      <v-tab-item v-for="lang in course.langs" :key="lang">
        <div class="input-spacer">
          <f-editor v-model="lesson.content.post[lang]" :key="lang" />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="js">
import FEditor from '../../shared/Editor.vue';

export default {
  components: {
    FEditor
  },
  computed: {
		course() {
			return this.$store.getters['course/current']
		},
    selectedLang(){
			return this.$store.getters['system/selectedInputsLang']
		},
    lesson() {
			return this.$store.state.lesson.current
		},
  },
  created(){
    if (this.$store.getters['system/isEditPage']) {
      this.$store.state.lesson.current.content = {}
      this.lesson['content'] = {...this.lesson.data}
    } else {
      this.lesson['content'] = {
        post: {}
      }
    }

  }
}
</script>
