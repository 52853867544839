<!-- Create Edit Form - Materials -->
<template>
  <div>
    <f-lang-selector :langs="course.langs" />
    <v-tabs-items v-model="selectedLang" v-if="!adding">
      <v-tab-item v-for="lang in course.langs" :key="lang">
        <div class="input-spacer">
          <div
            v-for="(item, index) of newContent.material"
            :key="index"
            class="material-item"
          >
            <v-row v-if="item[lang]">
              <v-col>
                <v-text-field
                  v-model="item[lang].name"
                  :rules="requiredRule"
                  hide-details
                  outlined
                  :label="
                    $tt.capitalize($t('labels.materialName')) +
                      $tt.uppercase(` ${index + 1} (${lang})`)
                  "
                />
              </v-col>
              <v-col>
                <v-file-input
                  v-if="!$store.getters['system/isEditPage']"
                  @change="file => setMaterial(index, file)"
                  :rules="requiredRule"
                  outlined
                  hide-details
                  :label="
                    $tt.capitalize($t('labels.file')) +
                      $tt.uppercase(` ${index + 1} (${lang})`)
                  "
                />
                <div
                  v-if="$store.getters['system/isEditPage']"
                  style="margin-bottom: -30px"
                >
                  <input-edit-file
                    :url="$API_URL + 'course/material/'"
                    :file="newContent.material[index][lang].file"
                    hide-details
                    @change="file => updateMaterial(index, file)"
                    @remove="() => {}"
                  />
                </div>
              </v-col>
              <v-col align-self="center" class="flex-grow-0">
                <v-btn
                  icon
                  @click.prevent="() => removeMaterial(index)"
                  :disabled="newContent.material.length === 1"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="material-actions">
            <v-btn
              v-if="!adding"
              color="primary"
              small
              elevation="0"
              @click.prevent="addMaterial"
              :disabled="sending"
              :loading="sending"
            >
              <v-icon>mdi-plus</v-icon> {{ $t('labels.addMaterial') }}
            </v-btn>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="js">
import validatorRules from '../../../helpers/validator_rules';
import InputEditFile from '../../shared/InputEditFile.vue';

export default {
  components: {
    InputEditFile
  },
	data: () => ({
		adding: false,
    newContent: {
      pdf: {},
      material: [
        {
          en: {
            name: '',
            file: null,
          },
          es: {
            name: '',
            file: null,
          },
          pt: {
            name: '',
            file: null,
          },
        },
      ],
    },
    flags: {
      isEditing: false,
      isValid: false,
    },
    sending: false,
	}),
  computed: {
		course() {
			return this.$store.getters['course/current']
		},
    selectedLang(){
			return this.$store.getters['system/selectedInputsLang']
		},
    lesson() {
			return this.$store.state.lesson.current
		},
    requiredRule() {
      return validatorRules.required(this.$t('rules.required'));
    },
  },
  watch: {
    newContent: {
      handler(value) {
        this.lesson.content = {
          materials: value.material
        }
      },
      deep: true
    }
  },
  created(){
		if (this.$store.getters['system/isEditPage']) {
			this.newContent.material = [...this.lesson.data.materials]
		}
	},
  methods: {
    addMaterial() {
      this.newContent.material.push({
        en: {
          name: '',
          file: null,
        },
        es: {
          name: '',
          file: null,
        },
        pt: {
          name: '',
          file: null,
        },
      });
    },
    setMaterial(index, file) {
      this.newContent.material[index][this.course.langs[this.selectedLang]].file = file;
      // create name of material if not exist
      if (!this.newContent.material[index][this.course.langs[this.selectedLang]].name) {
        this.newContent.material[index][
          this.course.langs[this.selectedLang]
        ].name = file.name
          .split('.')
          .slice(0, -1)
          .join('.');
      }
    },
    updateMaterial(index, file) {
      this.setMaterial(index, file.value);
    },
  }
}
</script>
<style lang="scss" scoped>
.material-item {
  background: #f7f7f7;
  border-radius: 7px;
  padding: 10px;
  margin: 5px 0 0 0;
}
.material-actions {
  margin: 10px 0 30px 0;
}
</style>
