<template>
  <div>
    <lms-default-loader v-if="isLoading" />

    <div v-if="!isLoading">
      <f-page-header :title="title" :prevLinks="prevLinks" />

      <f-form />
    </div>
  </div>
</template>

<script>
import FForm from '../../../components/Courses/lesson/CreateEditForm.vue';

export default {
  components: {
    FForm,
  },
  computed: {
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
    course() {
      return this.$store.getters['course/current'];
    },
    module() {
      return this.$store.getters['module/current'];
    },
    prevLinks() {
      let _prevLinks = [];
      _prevLinks = [
        {
          title: this.$t('labels.courses'),
          route: {
            name: 'course-list',
          },
        },
        {
          title: this.course.title[this.courseCurrentLanguage],
          route: {
            name: 'course-view',
            params: {
              id: this.course._id,
            },
          },
        },
      ];

      if (!this.$store.getters['course/isLinear']) {
        _prevLinks.push({
          title: this.module.title[this.courseCurrentLanguage],
          route: null,
        });
      }

      return _prevLinks;
    },
    title() {
      let title = this.$t('labels.addLesson');
      if (this.$store.getters['system/isEditPage']) {
        title = this.$t('labels.editLesson');
      }
      return title;
    },
  },
  created() {
    this.prepare();
  },
  methods: {
    async prepare() {
      await this.$store.dispatch('course/get', this.$route.params.courseid);

      if (!this.$store.getters['course/isLinear']) {
        await this.$store.dispatch(
          'module/select',
          this.$route.params.moduleid
        );
      }

      if (this.$store.getters['system/isEditPage']) {
        await this.$store.dispatch(
          'lesson/select',
          this.$route.params.lessonid
        );
      } else {
        this.$store.state.lesson.current = {
          title: {},
          type: '0',
          content: {},
        };
      }

      this.$store.commit('system/pageLoaded');
    },
  },
  beforeDestroy() {
    this.$store.commit('lesson/clear');
  },
};
</script>
